.arrow-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  padding: 0px;
  border-radius: 50px;
  background-color: rgba(91, 84, 84, 0.4);
}

.arrow-button:hover {
  background-color: rgba(65, 61, 61, 0.5);
  cursor: pointer;
}

.arrow-button:active {
  background-color: rgba(65, 61, 61, 0.5);
}

.arrow-button img {
  width: 20px;
  height: 20px;
}
