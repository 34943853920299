body {
  margin: 0;
  font-family: "Mukta", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none; /* remove underline */
  color: inherit; /* use the default text color */
}

.no-margin {
  margin: 0 !important;
}

/*Flex styles */
.flex-row {
  display: flex;
  flex-direction: row;
  justify-items: center;
}

.standard-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  padding-top: 100px;
  margin: 25px;
}

.flex-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.container-box {
  background-color: rgb(199,218,199);
  border-radius: 20px;
  padding: 20px;
}

.wide-container,
.center-container {
  display: flex;
  width: 100%;
  max-width: 1420px;
  margin: 0 auto;
}

.center-container {
  justify-content: center;
  align-items: center;
}

input {
  background-color: white !important;
}

.mobile-only {
  display: none;
}

/* Media query for mobile screens */
@media (max-width: 1050px) {
  .wide-container,
  .center-container {
    flex-direction: column;
  }
  .flex-page {
    flex-direction: column;
    gap: 0px;
  }
}

@media (max-width: 600px) {
  .mobile-only {
    display: unset;
  }

  .standard-page {
    margin: 5px;
  }
}

.green-stripe {
  background-color: rgb(0, 70, 9);
  height: 10px;
  width: 100%;
}

.red-stripe {
  background-color: rgb(172, 0, 0);
  height: 5px;
  width: 100%;
}
