/* MAIN PAGE */
.food-truck-img {
  width: 800px;
}

/* CONTAINERS */
.left-container,
.right-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.padding-margin {
  padding: 10px;
  margin: 10px !important;
}

.button-container {
  margin-top: 20px;
}

/* GOOGLE MAP EMBED */
.map {
  width: 100%;
  height: 450px;
  border: 3px solid;
}

/* BUTTONS */
.devpik-button {
  padding: 10px 20px;
  border: none;
  background-color: rgb(226, 24, 54);
  color: white;
  cursor: pointer;
  border-radius: 40px;
  padding: 20px;
  width: 100%;
  font-size: 25px;
  transition: filter 0.2s ease-in-out;
}

.devpik-button:hover {
  filter: brightness(0.8);
}

.button-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 30px;
}

/* form */
.service-form {
  display: flex;
  flex-direction: column;
  background-color: rgb(199, 218, 199);
  width: 600px;
  padding: 15px;
  border-radius: 15px;
}

.service-field {
  margin-bottom: 5px;
}

.service-field::placeholder {
  color: transparent;
}

/* ORDER CSS */
.order-button-selection {
  background-color: rgb(226, 24, 54); /* Green */
  border: none;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  border: rgb(226, 24, 54);
  border-style: solid;
  border-width: 3px;
  width: 215px;
  height: 55px;
  color: white;
  transition: filter 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
}

.order-button-selection:hover {
  filter: brightness(1.15);
}

.order-button-selection:active {
  filter: brightness(0.7);
}

.order-button-selection.selected {
  filter: brightness(0.7);
}

.delivery-form-container {
  margin-top: 20px;
}

.delivery-form {
  width: 700px;
  border: none;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: rgb(199, 218, 199);
  margin-left: 20px;
}

form div {
  margin-bottom: 10px;
}

.checkout-button {
  background-color: rgb(226, 24, 54);
  width: 300px;
  height: 45px;
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  font-family: Mukta;
  gap: 5px;
  color: white;
  transition: filter 0.2s ease-in-out;
}

.checkout-button:hover {
  filter: brightness(1.075);
}

.confirm-address-button {
  background-color: rgb(255, 24, 54);
  color: white;
  cursor: pointer;
  width: 125px;
  border-radius: 10px;
  text-align: center;
  transition: filter 0.25s ease-in-out;
}

.confirm-address-button:hover {
  filter: brightness(0.8);
}

.submit-cater-form {
  background-color: green;
}

.checkout-information {
  margin-left: 40px;
}

.details-form {
  display: flex;
  flex-direction: column;
  width: auto;
  background-color: rgb(199, 218, 199);
  padding: 10px 30px 30px 30px;
  border-radius: 15px;
}

.center-logo {
  display: none;
}

@media (max-width: 1350px) {
  .food-truck-img {
    width: 100%;
  }
  .delivery-form {
    width: 380px !important;
    margin: 0;
  }
  .updating-map {
    display: none;
  }
}

.youtube-player {
  width: 650px;
  height: 350px;
}

@media (max-width: 1050px) {
  .checkout-information {
    margin: 0;
  }
  .left-container, .right-container {
    text-align: justify;
    align-items: center;
  }
  .center-logo {
    display: inline;
  }
}

@media (max-width: 1450px) {
  .mini-slide {
    display: none;
  }
  .mini-headers {
    font-size: 20px;
  }
}

@media (max-width: 750px) {
  .youtube-player {
    width: 92vw;
    height: 50vw;
  }
  .service-form {
    width: 80vw;
  }
  .service-field {
    height: 40px;
    margin-top: 10px;
  }
  .service-field::placeholder {
    color: gray;
  }
  .service-label {
    display: none;
  }
}