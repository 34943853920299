@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");

.cardContainer {
  margin-top: 0.05rem;
  padding: 15px;
  margin-bottom: 25px;
  border-radius: 20px;
  background-color: rgb(199, 218, 199);
  font-family: "Work Sans", sans-serif;
}

.cardForm {
  margin: auto;
  margin-top: 3rem;
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-right: 15px;
}

.cardForm input {
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 15px;
  border: 2px solid grey;
  outline: none;
  color: #000;
  font-size: 1rem;
  font-family: "Work Sans", monospace;
  letter-spacing: 1px;
  transition: filter 0.3s ease-in-out;
}

.cardForm input:focus,
.cardForm input:active {
  filter: brightness(0.85);
  border: 2px solid black;
}

.cardForm input:hover {
  border: 2px solid black;
}

.cardForm .bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.bottom input[name="expiry"] {
  flex: 1;
}

.bottom input[name="cvc"] {
  width: 100px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.cardForm button {
  margin: 0.5rem;
  padding: 0.5rem;
  border: 2px solid rgb(69, 69, 69);
  outline: none;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Share Tech Mono", monospace;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: rgb(90, 137, 49);
  transition: filter 0.3s ease-in-out;
  cursor: pointer;
}

.cardForm button:hover {
  filter: brightness(0.8);
}

.cardForm button:hover,
.cardForm button:focus {
  border-color: black;
}

.submit-payment {
  border-radius: 30px;
  font-family: "Share Tech Mono", monospace;
  width: 100%;
  text-align: center;
  align-items: center;
}

.validation-icon {
  font-size: 1rem;
  position: absolute;
  transform: translateX(2450%);
}


.input-field {
  width: 100%;
}

.card-element-container {
  margin-top: 20px; 
  padding: 10px;
  border: 2px solid #808080; 
  background-color: white;
  width: 98%;
}

.payment-header {
  margin-left: 10px;
}

@media (max-width: 605px) {
  .cardForm {
    width: 74vw;
  }
  .validation-icon {
    font-size: 1rem;
    position: absolute;
    transform: translateX(63vw);
  }
}

@media (max-width: 1050px) {
  .payment-header {
    margin: 0;
  }
  .cardContainer {
    text-align: center;
  }
}

