/* StatusBox.css */

.status-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-style: solid;
    border-color: grey;
    border-radius: 15px;
    padding: 10px 10px 0px 10px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
    gap: 0.5rem;
    width: 200px;
    height: 150px;
    margin: 20px 0px 0px 0px;
  }
  
  .status-title {
    font-size: 1rem;
  }
  
  .status-icon {
    color: currentColor;
    font-size: 50px;
    padding: 0px;
    margin-top: -5px;
    margin-bottom: -60px; 
  }
  
  .status-time {
    font-size: 1.2rem; 
  }

  @media (max-width: 760px) {
    .status-box {
        width: 26vw;
    }

    .status-title {
    font-size: 2vw !important;
    }

    .status-time {
    font-size: 2.8vw !important;
    }
  }