.food-entry {
  width: 225px;
  min-height: 350px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px;
}

.food-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.food-photo {
  width: 180px;
  height: 200px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.food-photo img {
  max-width: 100%;
  max-height: 100%;
}

.food-title {
  margin: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.food-info p {
  margin: 5px 0;
}

.food-info label {
  margin-right: 5px;
}

.styles-checkboxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-size: 13px;
}

.style-checkbox {
  display: flex;
  align-items: center;
  width: 50%;
  box-sizing: border-box;
  padding-right: 5px;
  margin-bottom: 5px;
}

.style-checkbox input {
  margin-right: 5px;
}

.cart-add-button {
  margin-top: 10px;
  width: auto;
  background-color: red;
}

/* menu rows */
.menu-category {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1150px;
  justify-content: center;
}

.food-category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.choice-button {
  background-color: rgb(255, 24, 54);
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 18px;
  margin-top: 4px;
  transition: filter 0.2s ease-in-out;
}

.choice-button:hover {
  filter: brightness(1.2);
}

.food-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  user-select: none;
}

.category-icon {
  margin-left: 10px;
  font-size: 24px;
  color: black;
}

.arrow-selector {
  font-size: 2rem;
  color: red;
  font-weight: bold;
  transition: rotate 0.3s ease-in-out;
}

.rotate {
  transition: rotate 0.3s ease-in-out;
  rotate: 90deg;
}

@media (max-width: 600px) {
  .food-entry {
    width: 140px;
    height: auto;
    margin: 0px;
  }
  .food-photo {
    width: 120px;
    height: 100px;
  }
}
