.order-cart-container {
  display: flex;
  flex-direction: column;
}

.order-cart {
  width: 490px;
  padding: 10px;
  border-radius: 20px;
  background-color: rgb(199, 218, 199);
  border-style: solid;
  border-width: 3px;
  border-color: transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  margin-bottom: 10px;
}

.order-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 5px 0px;
}

.remove-button,
.add-button {
  height: 30px;
  width: 30px;
  color: white;
  font-size: 20px;
  border: none;
  transition: filter 0.3s ease-in-out;
  text-align: center;
}

.remove-button {
  background-color: rgb(236, 84, 84);
  border-radius: 5px 0px 0px 5px;
}

.add-button {
  background-color: rgb(60, 182, 91);
  border-radius: 0px 5px 5px 0px;
}

.remove-button:hover,
.add-button:hover {
  cursor: pointer;
  filter: brightness(0.8);
}

.cart-item-photo {
  border: 2px solid black;
  background-color: white;
  margin: 5px;
}

.cart-item-entry {
  font-weight: bold;
}

.font-size-25 {
  font-size: 25px !important;
}

.font-size-default {
  font-size: 16px;
}

.fee {
  font-weight: bold;
  font-size: 13px;
}

.cart-toggle-button {
  border: solid 2px black !important;
  border-radius: 2px !important;
  cursor: pointer !important;
  cursor: pointer;
  text-align: center;
}

.continue-order-button {
  background-color: rgb(226, 24, 54);
  color: white !important;
  width: 170px;
  border-radius: 20px;
  padding: 5px;
  align-items: center;
  text-align: center;
  transition: filter 0.2s ease-in-out;
}

.continue-order-button:hover {
  filter: brightness(1.1);
  cursor: pointer;
}

/* TIP SECTION */
.tip-section {
  margin-top: 20px;
  width: 490px;
  background-color: rgb(199, 218, 199);
  padding: 12px 18px 20px 18px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.tip-options {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.tip-button {
  background-color: rgb(226, 24, 54);
  border: none;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 20px;
  border: rgb(226, 24, 54);
  border-style: solid;
  border-width: 3px;
  width: 100px;
  height: 55px;
  color: white;
  transition: filter 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
}

.tip-button:hover {
  filter: brightness(1.15);
}

.tip-button:active {
  filter: brightness(0.7);
}

.tip-button.active {
  filter: brightness(0.7);
}

.cart-item-photo {
  width: 75px;
  height: 75px;
}

.price-each {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

@media (max-width: 600px) {
  .order-cart-container {
    width: 100%;
  }
  .order-cart {
    width: 100%;
  }
  .price-each {
    font-size: 11px !important;
  }
  .remove-button,
  .add-button {
    width: 30px;
    height: 30px;
  }
  .price-each {
    display: flex;
    align-items: center;
    margin-left: 0;
  }
}

@media (max-width: 1050px) {
  .delivery-form {
    margin: 0;
  }
}


@media (max-width: 450px) {
  .cart-item-photo {
    width: 10vw;
    height: 10vw;
  }
}

@media (max-width: 330px) {
  .cart-item-photo {
    display: none
  }
}