/* Navbar.css */

/* -- NAVBAR CONFIGURATION -- */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 10px 30px 10px 30px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 100;
  transition: height 0.5s ease-in-out;
}



.desktop-links img {
  height: 55px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.desktop-links img:hover {
  transform: scale(1.05);
}

.nav-link {
  color: rgb(0, 70, 9);
  font-weight: bold;
}

.nav-link:hover {
  color: rgb(4, 182, 28);
}

.shopping-cart {
  font-size: 40px;
  color: rgb(0, 118, 16);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.shopping-cart:hover {
  transform: scale(1.15);
}



.mobile-links {
  height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  transition: height 0.5s ease-in-out;
}


@media (max-width: 600px) {
  .navbar {
    display: flex;
    flex-direction: column;
    transition: height 0.5s ease-in-out;
  }
  .nav-link {
    font-size: 1.5rem;
  }
}

.desktop-links {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.expanded {
  height: 210px;
  transition: height 0.5s ease-in-out;
}

