.image-carousel {
  position: relative;
  background-color: rgb(222, 222, 222);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;
  user-select: none;
}

.carousel-image {
	width: 100%;
	height: 100%;
	object-fit: cover; /* Ensure the image fills the space while maintaining aspect ratio */
  }

.next-button,
.prev-button {
  position: absolute;
}

.next-button {
  right: 0;
  transform: rotate(90deg); /* Fixed the rotate property */
}

.prev-button {
  left: 0;
  transform: rotate(-90deg); /* Fixed the rotate property */
}

.thumbnails {
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 385px;
  margin-top: 10px;
  transition: opacity 0.5s ease;
}

.thumbnails img {
  width: 60px;
  height: 60px;
  object-fit: cover; /* Ensure the image fills the space while maintaining aspect ratio */
  background-color: rgb(222, 222, 222);
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  user-select: none;
}

.thumbnails img:last-child,
.thumbnails img:nth-child(6n) {
  /* No margin for last item in each line break*/
  margin-right: 0;
}

.thumbnails img.inactive {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .image-carousel {
    width: 100% !important;
    height: auto;
  }
  .thumbnails {
    max-width: 80vw;
  }
  .thumbnails img {
    width: calc(80vw * 60 / 385);
    height: calc(80vw * 60 / 385);
    object-fit: cover; /* Ensure the image fills the space while maintaining aspect ratio */
    background-color: rgb(222, 222, 222);
    margin-right: calc(80vw * 5 / 385);
    margin-bottom: calc(80vw * 5 / 385);
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
}
