.profile-picture-container {
	position: relative;
}

.profile-picture-wrapper {
	position: relative;
	display: inline-block;
}

.overlay {
	position: absolute;
	top: -15.8px;
	left: 0;
	width: 100%;
	height: 95%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	opacity: 0;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.5);
	transition: opacity 0.3s ease;
}

.overlay-text {
	color: white;
	font-size: 1.2rem;
}

.profile-picture-wrapper:hover .overlay {
	opacity: 1;
}

.profile-picture {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	object-fit: cover;
	object-position: center;
}

.profile-picture-custom {
	transition: filter 0.3s ease;
}

@media screen and (max-width: 1175px) {
	.profile-page-layout {
		display: flex;
		flex-direction: column;
	}
}

.profile-picture-custom:hover {
	filter: brightness(0.5);
}

.collection-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.collection-link {
	flex: 1;
}

.bookmark-container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-left: 10px;
	padding-right: 20px;
}

.edit-container {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding-left: 5px;
	padding-right: 5px;
}

.edit-neutral {
	width: 24px;
	height: 24px;
	transition: transform 0.2s ease;
}

.edit-neutral:hover {
	transform: scale(1.1);
}

.vertical-form {
	min-height: 110px;
	max-height: 110px;
	min-width: 360px;
	overflow-y: auto;
}

.vertical-form::-webkit-scrollbar {
	width: 10px;
}

.vertical-form::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.vertical-form::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 5px;
}

.vertical-form::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.error-message {
	color: red;
	font-size: 14px;
	margin-top: 5px;
	text-align: center;
}

.collection-layout {
	display: flex;
	flex-direction: column;
	width: 400px;
	border-radius: 8px;
	height: 528px;
}

.profile-height {
	height: 528px;
}

.collection-list {
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 0;
	overflow-y: auto;
}

.collection-list-bookmark {
	max-height: 180px;
}

.collection-list-listing {
	max-height: 135px;
}

.collection-list::-webkit-scrollbar {
	width: 10px; /* Set the width of the scrollbar */
}

.collection-list::-webkit-scrollbar-track {
	background: #f1f1f1; /* Color of the scrollbar track */
}

.collection-list::-webkit-scrollbar-thumb {
	background: #888; /* Color of the scrollbar thumb */
	border-radius: 5px; /* Roundness of the scrollbar thumb */
}

.collection-list::-webkit-scrollbar-thumb:hover {
	background: #555; /* Color of the scrollbar thumb on hover */
}

.collection-item {
	margin-bottom: -7px;
}

.collection-container {
	display: inline-block;
	vertical-align: middle;
}

.collection-image {
	width: 45px;
	height: 45px;
	margin-right: 15px;
}

.collection-text {
	display: inline-block;
	vertical-align: middle;
}

.listing-height {
	margin-top: -25px;
	height: 187px;
}

.bookmarks-height {
	margin-top: -25px;
	height: 180px;
	margin-bottom: 20px;
}

.compact {
	margin-bottom: -5px;
}

.vertical-left {
	display: flex;
	align-items: left;
	justify-content: left;
	padding-left: 26px;
	padding-top: 16px;
}

.small-button {
	height: 35px;
	font-size: 12px;
	vertical-align: center;
	margin-top: 10px;
}

.small-width {
	width: 100px;
}

.medium-width {
	width: 140px;
}

.lookup-btn {
	background-color: white !important;
	border: 2px solid lightgrey;
	border-radius: 8px;
	color: black;
	width: 40px;
	height: 45px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
}

.lookup-btn:hover {
	background-color: rgba(211, 211, 211, 0.916);
}

.overflow-container {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 15px;
	overflow: "ellipsis";
	padding-left: 13px;
	margin-top: 33px;
}

.user-bio {
	text-align: justify;
}

.line-length {
	line-height: 1.25;
}