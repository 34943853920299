.notification {
	position: fixed;
	bottom: 20px;
	left: 20px;
	padding: 10px 10px;
	border-radius: 25px;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	max-height: 60px;
	display: flex;
	align-items: center;
	vertical-align: center;
	justify-content: center;
	z-index: 10000;
}

.notification.visible {
	opacity: 0.98;
}

.notification.success {
	background-color: rgb(139, 218, 170);
}

.notification.error {
	background-color: rgb(207, 152, 152);
}

.notif-text {
	color: black;
	opacity: 100;
	font-size: 15px;
}
