.scrollToTopButton {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 99;
	opacity: 0;
	transition: opacity 0.5s ease;
}

.scrollToTopButton.show {
	opacity: 1;
}
