@font-face {
	font-family: 'Jellee';
	src: url('../assets/fonts/jellee.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
  }
  
  .footer {
	background-color: #156030;
	color: #fff;
	padding: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	bottom: 0;
  }
  
  .social-icons {
	display: flex;
  }
  
  .social-link {
	margin-right: 15px;
  }
  
  .powered-by {
	flex-grow: 1;
	text-align: center;
  }
  
  .powered-by .team-logo {
	font-size: 20px;
	font-family: 'Mukta', sans-serif; 
  }
  
  .powered-by-text {
	font-family: 'Jellee', sans-serif; 
	cursor: pointer;
  }
  
  .terms {
	flex-shrink: 0;
	margin-right: 50px;
  }
  
  .footer-link {
	color: white;
  }
  
  .footer-link:hover {
	color: lightgrey;
  }
  
  @media (max-width: 768px) {
	.footer {
	  flex-direction: column;
	  align-items: center;
	}
	
	.social-icons {
	  margin-bottom: 10px;
	}

	.terms {
		display: none;
	  }
  }
  