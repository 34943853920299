.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333; /* Color of the spinner */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Apply spinning animation */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
